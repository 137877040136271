@import "styles/scss/_mixins.scss";

.contentContainer {
  padding-right: 64px;
  padding-left: 64px;

  @include downXl {
    padding-right: 32px;
    padding-left: 32px;
  }

  @include downMd {
    padding-right: 16px;
    padding-left: 16px;
  }
}
