@import "styles/scss/_mixins";

.logo {
  display: block;
  width: 190px;
  max-width: 100%;

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  @include downMd {
    width: 143px;
  }

  // Super small phones. Just in case someone use it
  @include downXxs {
    width: 100px;
  }
}
