@import "styles/scss/_mixins";

$drawerWidth: 300px; // Properties from MUI theme. If change them in theme.ts change here also

.main {
  position: relative;
  width: 100%;
  min-width: 100%;
  // padding: 48px 64px;
  overflow: auto;
}

// SideNav opening props
// Change JS and CSS breakpoints together to match:
// src/templates/UserTemplate/components/SideNav/index.tsx
// src/templates/UserTemplate/components/Main/style.module.scss
@include upLg {
  .main {
    margin-left: -$drawerWidth;
    transition: all 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms; // Properties from MUI theme. If change them in theme.ts change here also
  }
  
  .open {
    width: calc(100% - $drawerWidth);
    min-width: calc(100% - $drawerWidth);
    margin-left: 0;
  }
}
