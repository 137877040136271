// Tempalte is very important part. Custom css used instead of MUI Flex/Stack to control
.userTemplate {
	display: flex;
	flex-direction: column;
	height: 100%;
	min-width: 100%;
}

.userTemplateContent {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  overflow: auto;
}
