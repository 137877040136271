@import "styles/scss/_variables";

// Breakpoints Down
@mixin downXl() {
  @media (max-width: #{$breakpoint-xl - 1}px) {
    @content;
  }
}

@mixin downLg() {
  @media (max-width: #{$breakpoint-lg - 1}px) {
    @content;
  }
}

@mixin downMd() {
  @media (max-width: #{$breakpoint-md - 1}px) {
    @content;
  }
}

@mixin downSm() {
  @media (max-width: #{$breakpoint-sm - 1}px) {
    @content;
  }
}

// Super small phones. Prevent of use this query. Use it only if you are sure it's needed
// https://www.browserstack.com/guide/responsive-design-breakpoints
@mixin downXxs {
  @media (max-width: 359px) {
    @content;
  }
}

// Breakpoints Up
@mixin upSm() {
  @media (min-width: #{$breakpoint-sm}px) {
    @content;
  }
}

@mixin upMd() {
  @media (min-width: #{$breakpoint-md}px) {
    @content;
  }
}

@mixin upLg() {
  @media (min-width: #{$breakpoint-lg}px) {
    @content;
  }
}

@mixin upXl() {
  @media (min-width: #{$breakpoint-xl}px) {
    @content;
  }
}
