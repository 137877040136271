// ONLY Styles that cannot be applied with css modules and typescript

// For UserLayout. Full height. SideNav and Main scrolls independently
body,
html,
#root {
  height: 100%;
}

body {
  overflow: hidden;
}

// Utilities
.h-100 {
  height: 100%;
}
